import React, { Component } from 'react';
import { convertTime } from '../../utils/helper';
import { sendMessage } from '../../services/chat';
import socket from '../../utils/socket-io';
import { isMobile } from 'react-device-detect';
// import { Picker } from 'emoji-mart';

class MessageList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            textMessage: '',
        };
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    };

    componentDidUpdate() {
        this.scrollToBottom();
    }

    componentDidMount() {
        const { updateMessages } = this.props;

        this.scrollToBottom();

        socket.on('resNewMessage', (data) => {
            updateMessages(data);
        });
    }

    handleSubmit = (e) => {
        const { messages, handleSendMessage } = this.props;
        const { textMessage } = this.state;
        e.preventDefault();
        const chatId = messages[0].id.remote._serialized || messages[0].id.remote;
        handleSendMessage(chatId, textMessage);
        this.setState({ textMessage: '' });
    };

    setTextMessage = (text) => {
        this.setState({ textMessage: text });
    };

    addEmoji = (e) => {
        const { textMessage } = this.state;

        let emoji = e.native;
        this.setState({
            textMessage: textMessage + emoji,
        });
    };

    handleUserKeyPress = (e) => {
        // if device is mobile, return
        if (isMobile) return;
        if (e.key === 'Enter' && !e.shiftKey) {
            // e.preventDefault();
            this.handleSubmit(e);
        }
    };

    render() {
        const { messages, updateFlag, toggleMessageOn, toggleMessageOff, fetchAdminList } = this.props;
        let { textMessage } = this.state;

        return (
            // <!-- Start User chat -->
            <div className={`user-chat w-100 overflow-hidden ${toggleMessageOn ? 'user-chat-show' : ''}`}>
                <div className='d-lg-flex'>
                    {/* <!-- start chat conversation section --> */}
                    <div className='w-100 overflow-hidden position-relative'>
                        <div className='p-3 p-lg-4 border-bottom'>
                            <div className='row align-items-center'>
                                <div className='col-sm-4 col-8'>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-block d-lg-none me-2 ms-0'>
                                            <a
                                                className='user-chat-remove text-muted font-size-16 p-2'
                                                onClick={() => toggleMessageOff()}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <i className='ri-arrow-left-s-line'></i>
                                            </a>
                                        </div>
                                        <div className='me-3 ms-0'>
                                            <div className='chat-user-img align-self-center'>
                                                <div className='avatar-xs'>
                                                    <span className='avatar-title rounded-circle bg-soft-primary text-primary'>
                                                        C
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex-1 overflow-hidden'>
                                            <h5 className='font-size-16 mb-0 text-truncate'>
                                                <a href='#' className='text-reset user-profile-show'>
                                                    {messages.length > 0 ? messages[0].id.remote.user : ''}
                                                </a>
                                                <i className='ri-record-circle-fill font-size-10 text-success d-inline-block ms-1'></i>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-8 col-4'>
                                    <ul className='list-inline user-chat-nav text-end mb-0'>
                                        {/* <li className='list-inline-item'>
                                            <div className='dropdown'>
                                                <button
                                                    className='btn nav-btn dropdown-toggle'
                                                    type='button'
                                                    data-bs-toggle='dropdown'
                                                    aria-haspopup='true'
                                                    aria-expanded='false'
                                                >
                                                    <i className='ri-search-line'></i>
                                                </button>
                                                <div className='dropdown-menu p-0 dropdown-menu-end dropdown-menu-md'>
                                                    <div className='search-box p-2'>
                                                        <input
                                                            type='text'
                                                            className='form-control bg-light border-0'
                                                            placeholder='Search..'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </li> */}
                                        <li className='list-inline-item'>
                                            <div className='dropdown'>
                                                <button
                                                    className='btn nav-btn dropdown-toggle'
                                                    type='button'
                                                    data-bs-toggle='dropdown'
                                                    aria-haspopup='true'
                                                    aria-expanded='false'
                                                >
                                                    <i className='ri-more-fill'></i>
                                                </button>
                                                <div className='dropdown-menu dropdown-menu-end'>
                                                    {/* <a
                                                    className='dropdown-item d-block d-lg-none'
                                                    href='#'
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#audiocallModal'
                                                >
                                                    Audio <i className='ri-phone-line float-end text-muted'></i>
                                                </a> */}
                                                    {/* <a
                                                    className='dropdown-item d-block d-lg-none'
                                                    href='#'
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#videocallModal'
                                                >
                                                    Video <i className='ri-vidicon-line float-end text-muted'></i>
                                                </a> */}
                                                    {/* <a className='dropdown-item' href='#'>
                                                    Archive <i className='ri-archive-line float-end text-muted'></i>
                                                </a> */}
                                                    <a
                                                        className='dropdown-item'
                                                        style={{ cursor: 'pointer' }}
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#forwardModal'
                                                        onClick={() => fetchAdminList()}
                                                    >
                                                        Forward To
                                                        <i className='ri-chat-forward-line float-end text-muted'></i>
                                                    </a>
                                                    <a
                                                        className='dropdown-item'
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() =>
                                                            updateFlag(
                                                                messages[0].id.remote._serialized ||
                                                                    messages[0].id.remote,
                                                                'solved'
                                                            )
                                                        }
                                                    >
                                                        Mark As Solved
                                                        <i className='ri-chat-check-line float-end text-muted'></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end chat user head --> */}

                        {/* <!-- start chat conversation --> */}
                        <div className='chat-conversation p-3 p-lg-4' data-simplebar='init'>
                            <ul className='list-unstyled mb-0'>
                                {messages.map((message) => {
                                    return (
                                        <li className={message.fromMe ? 'right' : ''} key={message.id.id}>
                                            <div className='conversation-list'>
                                                <div className='chat-avatar'>
                                                    {/* <img src='assets/images/users/avatar-4.jpg' alt='' /> */}
                                                    <div className='avatar-xs'>
                                                        <span className='avatar-title rounded-circle bg-soft-primary text-primary'>
                                                            {message.fromMe ? 'Me' : 'C'}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className='user-chat-content'>
                                                    <div className='ctext-wrap'>
                                                        <div className='ctext-wrap-content'>
                                                            {message.hasMedia &&
                                                            message.body !== 'File size is too big' ? (
                                                                <ul className='list-inline message-img  mb-0'>
                                                                    <li className='list-inline-item message-img-list me-2 ms-0'>
                                                                        <div>
                                                                            <a
                                                                                className='popup-img d-inline-block m-1'
                                                                                href={message.body}
                                                                                title='Project 1'
                                                                            >
                                                                                <img
                                                                                    src={message.body}
                                                                                    alt=''
                                                                                    className='rounded border'
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                        {/* <div className='message-img-link'>
                                                                            <ul className='list-inline mb-0'>
                                                                                <li className='list-inline-item'>
                                                                                    <a href='#'>
                                                                                        <i className='ri-download-2-line'></i>
                                                                                    </a>
                                                                                </li>
                                                                                <li className='list-inline-item dropdown'>
                                                                                    <a
                                                                                        className='dropdown-toggle'
                                                                                        href='#'
                                                                                        role='button'
                                                                                        data-bs-toggle='dropdown'
                                                                                        aria-haspopup='true'
                                                                                        aria-expanded='false'
                                                                                    >
                                                                                        <i className='ri-more-fill'></i>
                                                                                    </a>
                                                                                    <div className='dropdown-menu'>
                                                                                        <a
                                                                                            className='dropdown-item'
                                                                                            href='#'
                                                                                        >
                                                                                            Copy{' '}
                                                                                            <i className='ri-file-copy-line float-end text-muted'></i>
                                                                                        </a>
                                                                                        <a
                                                                                            className='dropdown-item'
                                                                                            href='#'
                                                                                        >
                                                                                            Save{' '}
                                                                                            <i className='ri-save-line float-end text-muted'></i>
                                                                                        </a>
                                                                                        <a
                                                                                            className='dropdown-item'
                                                                                            href='#'
                                                                                        >
                                                                                            Forward{' '}
                                                                                            <i className='ri-chat-forward-line float-end text-muted'></i>
                                                                                        </a>
                                                                                        <a
                                                                                            className='dropdown-item'
                                                                                            href='#'
                                                                                        >
                                                                                            Delete{' '}
                                                                                            <i className='ri-delete-bin-line float-end text-muted'></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div> */}
                                                                    </li>
                                                                </ul>
                                                            ) : (
                                                                <p className='mb-0'>{message.body}</p>
                                                            )}
                                                            <p className='chat-time mb-0'>
                                                                <i className='ri-time-line align-middle'></i>{' '}
                                                                <span className='align-middle'>
                                                                    {convertTime(message.timestamp)}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className='dropdown align-self-start'>
                                                            <a
                                                                className='dropdown-toggle'
                                                                href='#'
                                                                role='button'
                                                                data-bs-toggle='dropdown'
                                                                aria-haspopup='true'
                                                                aria-expanded='false'
                                                            >
                                                                <i className='ri-more-2-fill'></i>
                                                            </a>
                                                            <div className='dropdown-menu'>
                                                                {/* <a className='dropdown-item' href='#'>
                                                                Copy{' '}
                                                                <i className='ri-file-copy-line float-end text-muted'></i>
                                                            </a>
                                                            <a className='dropdown-item' href='#'>
                                                                Save{' '}
                                                                <i className='ri-save-line float-end text-muted'></i>
                                                            </a> */}
                                                                <a className='dropdown-item'>
                                                                    Forward
                                                                    <i className='ri-chat-forward-line float-end text-muted'></i>
                                                                </a>
                                                                <a className='dropdown-item' href='#'>
                                                                    Delete
                                                                    <i className='ri-delete-bin-line float-end text-muted'></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className='conversation-name'>Doris Brown</div> */}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                                <div
                                    style={{ float: 'left', clear: 'both' }}
                                    ref={(el) => {
                                        this.messagesEnd = el;
                                    }}
                                ></div>
                                {/* 
                            <li>
                                <div className='chat-day-title'>
                                    <span className='title'>Today</span>
                                </div>
                            </li> */}
                            </ul>
                        </div>
                        {/* <!-- end chat conversation end --> */}

                        {/* <!-- start chat input section --> */}
                        <div className='chat-input-section p-3 p-lg-2 border-top mb-0'>
                            <form className='row g-0'>
                                <div className='col'>
                                    <textarea
                                        type='text'
                                        className='form-control form-control-lg bg-light border-light'
                                        placeholder='Enter Message...'
                                        value={textMessage}
                                        onChange={(e) => this.setTextMessage(e.target.value)}
                                        onKeyPress={(e) => this.handleUserKeyPress(e)}
                                    />
                                </div>
                                <div className='col-auto'>
                                    <div className='chat-input-links ms-md-2 me-md-0'>
                                        <ul className='list-inline mb-0'>
                                            <li
                                                className='list-inline-item'
                                                data-bs-toggle='tooltip'
                                                data-bs-placement='top'
                                                title='Emoji'
                                            >
                                                <button
                                                    type='button'
                                                    className='btn btn-link text-decoration-none font-size-16 btn-lg waves-effect'
                                                >
                                                    <i className='ri-emotion-happy-line'></i>
                                                </button>
                                            </li>
                                            <li
                                                className='list-inline-item'
                                                data-bs-placement='top'
                                                title='Attached File'
                                                style={{ cursor: 'pointer' }}
                                                data-bs-toggle='modal'
                                                data-bs-target='#uploadImageModal'
                                            >
                                                <button
                                                    type='button'
                                                    className='btn btn-link text-decoration-none font-size-16 btn-lg waves-effect'
                                                >
                                                    <i className='ri-attachment-line'></i>
                                                </button>
                                            </li>
                                            <li className='list-inline-item'>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light'
                                                    onClick={(e) => this.handleSubmit(e)}
                                                >
                                                    <i className='ri-send-plane-2-fill'></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* <!-- end chat input section --> */}
                    </div>
                    {/* <!-- end chat conversation section --> */}
                </div>
            </div>
        );
    }
}
export default MessageList;
