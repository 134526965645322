import axios from 'axios';

let baseURL = process.env.REACT_APP_BASE_URL + '/api/v2';
const api = axios.create({
    baseURL,
});

api.interceptors.request.use(
    async (config) => {
        let token = await localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;
