import api from '../api';
import jwt_decode from 'jwt-decode';

/**
 * @DESC login for client
 */
export const login = async (data, props) => {
    try {
        const response = await api.post('/users/login', data);
        if (response.data.success) {
            localStorage.setItem('token', response.data.data.token);
            props.history.push('/dashboard');
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        alert(error);
        throw error;
    }
};

/**
 * @DESC logout and remove token session
 * @returns true/false
 */
export const logout = (props) => {
    let tokenRemoved = localStorage.removeItem('token');
    console.log('remove status', tokenRemoved);
    props.history.push('/');
    return true;
};

/**
 * @DESC get id user of admin from local storage
 */
export const getIdAdmin = () => {
    let token = localStorage.getItem('token');
    const decoded = jwt_decode(token);
    return decoded.user_id;
};

/**
 * @DESC get user details from token
 */
export const getDetailsOfUser = () => {
    let token = localStorage.getItem('token');
    const decoded = jwt_decode(token);
    return decoded;
};

/**
 * @DESC getListOfAdmin
 */
export const getAdminList = async () => {
    try {
        const response = await api.get('/users/admin');
        if (response.data.success) {
            return response.data.data;
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        alert(error);
        throw error;
    }
};
