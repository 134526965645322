import React, { Component } from 'react';
import Login from '../views/Login';
import NotFoundPage from '../views/NotFoundPage';
import Dashboard from '../views/Dashboard';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

class router extends Component {
    toDashboard = (props) => {
        const token = localStorage.getItem('token');

        if (token) {
            return <Dashboard {...props} />;
        } else {
            return <Redirect to='/' />;
        }
    };

    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        <Route exact path='/' component={Login} />
                        <Route path='/dashboard' component={(props) => this.toDashboard(props)} />
                        <Route path='*' component={NotFoundPage} />
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default router;
