const publicVapidKey = 'BCssgWSNsNcU61uS5K90SVKVK0-a7WF3GNmiKEpDgmxoWhP7jWq5WC7EQ3HVw5zrIOZRruvYj-ra_JaL3mP4oa4';
import api from './api';

console.log('triggered');

if ('serviceWorker' in navigator) {
    console.log('Registering service worker');

    // run().catch((error) => console.error(error));
}

export async function run(selfId) {
    let data = {}
    data.adminId = selfId
    console.log('Registering service worker');
    const registration = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/worker.js`, { scope: '/' });
    console.log('Registered service worker');

    console.log('Registering push');
    data.subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
    });
    console.log('Registered push');

    console.log('Sending push');

    const response = await api.post('/session/subscribe', data);
    if (response.data.success) {
        alert(response.data.message);
        console.log('success subs');
    } else {
        alert(response.data.message);
        console.log('error');
    }
}

// Boilerplate borrowed from https://www.npmjs.com/package/web-push#using-vapid-key-for-applicationserverkey
function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
