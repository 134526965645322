import React, { Component } from 'react';
// import jwt_decode from 'jwt-decode';
import { login } from '../services/user';
class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataLogin: {
                username: '',
                password: '',
            },
        };
    }

    handleSubmit = (event) => {
        const { dataLogin } = this.state;

        event.preventDefault();
        const result = login(dataLogin, this.props);
    };

    render() {
        const { dataLogin } = this.state;
        return (
            <div className='account-pages my-5 pt-sm-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8 col-lg-6 col-xl-5'>
                            <div className='text-center mb-4'>
                                <h4>Login</h4>
                                <p className='text-muted mb-4'>Login to continue to Chat-App</p>
                            </div>

                            <div className='card'>
                                <div className='card-body p-4'>
                                    <center>
                                        <a href='/' className='auth-logo mb-2 d-block'>
                                            <img src='assets/images/logo_uin.png' alt='' height='50' />
                                        </a>
                                    </center>
                                    <div className='p-3'>
                                        <form onSubmit={this.handleSubmit}>
                                            <div className='mb-3'>
                                                <label className='form-label'>Username</label>
                                                <div className='input-group mb-3 bg-soft-light rounded-3'>
                                                    <span
                                                        className='input-group-text text-muted'
                                                        id='basic-addon3'
                                                    >
                                                        <i className='ri-user-2-line'></i>
                                                    </span>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-lg border-light bg-soft-light'
                                                        placeholder='Enter Username'
                                                        aria-label='Enter Username'
                                                        aria-describedby='basic-addon3'
                                                        value={dataLogin.username}
                                                        onChange={(event) => {
                                                            dataLogin.username = event.target.value;
                                                            this.setState({
                                                                dataLogin,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='mb-4'>
                                                <div className='float-end'>
                                                    {/* <a
                                                        href='auth-recoverpw.html'
                                                        className='text-muted font-size-13'
                                                    >
                                                        Forgot password?
                                                    </a> */}
                                                </div>
                                                <label className='form-label'>Password</label>
                                                <div className='input-group mb-3 bg-soft-light rounded-3'>
                                                    <span
                                                        className='input-group-text text-muted'
                                                        id='basic-addon4'
                                                    >
                                                        <i className='ri-lock-2-line'></i>
                                                    </span>
                                                    <input
                                                        type='password'
                                                        className='form-control form-control-lg border-light bg-soft-light'
                                                        placeholder='Enter Password'
                                                        aria-label='Enter Password'
                                                        aria-describedby='basic-addon4'
                                                        value={dataLogin.password}
                                                        onChange={(event) => {
                                                            dataLogin.password = event.target.value;
                                                            this.setState({
                                                                dataLogin,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className='form-check mb-4'>
                                                {/* <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='remember-check'
                                                />
                                                <label
                                                    className='form-check-label'
                                                    for='remember-check'
                                                >
                                                    Remember me
                                                </label> */}
                                            </div>

                                            <div className='d-grid'>
                                                <button
                                                    className='btn btn-primary waves-effect waves-light'
                                                    type='submit'
                                                >
                                                    Login
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
