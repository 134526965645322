import { convertTime } from '../../utils/helper';

export default function SolvedChats(props) {
    return (
        // <!-- Start chats tab-pane -->
        <div
            className='tab-pane fade show'
            id='pills-solved-chats'
            role='tabpanel'
            aria-labelledby='pills-solved-chat-tab'
        >
            {/* <!-- Start chats content --> */}
            {/* <div> */}
            <div className='px-4 pt-4'>
                <h4 className='mb-4'>Solved Chats</h4>
                <div className='search-box chat-search-box'>
                    <div className='input-group mb-3 rounded-3'>
                        <h6>List of Solved Chats</h6>
                        {/* <span className='input-group-text text-muted bg-light pe-1 ps-3' id='basic-addon1'>
                            <i className='ri-search-line search-icon font-size-18'></i>
                        </span>
                        <input
                            type='text'
                            className='form-control bg-light'
                            placeholder='Search messages or users'
                            aria-label='Search messages or users'
                            aria-describedby='basic-addon1'
                        /> */}
                    </div>
                </div>
                {/* <!-- Search Box--> */}
            </div>
            {/* <!-- .p-4 --> */}
            {/* <!-- Start chat-message-list --> */}
            <div className='px-2'>
                <h5 className='mb-3 px-3 font-size-16'></h5>

                <div className='chat-message-list' style={{ height: '83vh' }} data-simplebar>
                    <ul className='list-unstyled chat-list chat-user-list'>
                        {props.chats.map((chat) =>
                            chat.flag === 'solved' ? (
                                <li key={chat._id}>
                                    <a role='button' onClick={() => props.handleOnClickChat(chat.chatId)}>
                                        <div className='d-flex'>
                                            <div className='chat-user-img align-self-center me-3 ms-0'>
                                                <div className='avatar-xs'>
                                                    <span className='avatar-title rounded-circle bg-soft-primary text-primary'>
                                                        U
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='flex-1 overflow-hidden'>
                                                <h5 className='text-truncate font-size-15 mb-1'>
                                                    {chat.name || chat.chatId.split('@')[0]}
                                                </h5>
                                                <p className='chat-user-message text-truncate mb-0'>
                                                    {chat.lastMessage || ''}
                                                </p>
                                            </div>
                                            <div className='font-size-11'>{convertTime(chat.timestamp)}</div>
                                            {!chat.isRead ? (
                                                <div className='unread-message'>
                                                    <span className='badge badge-soft-danger rounded-pill'>
                                                        1
                                                    </span>
                                                </div>
                                            ) : (
                                                false
                                            )}
                                        </div>
                                    </a>
                                </li>
                            ) : (
                                false
                            )
                        )}
                    </ul>
                </div>
            </div>
            {/* <!-- End chat-message-list --> */}
            {/* </div> */}
            {/* <!-- Start chats content --> */}
        </div>
        // {/* <!-- End chats tab-pane --> */}
    );
}
