import api from '../api';

/**
 * @DESC getChats
 */
export const getChats = async () => {
    try {
        const response = await api.get('/chats/getChatsForClient');
        if (response.data.success === true) {
            return response.data.data;
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        alert(error);
        throw error;
    }
};

/**
 * @DESC getChats unsolved
 */
export const getUnsolvedChats = async () => {
    try {
        const response = await api.get('/chats/getUnsolvedChatsForClient');
        if (response.data.success === true) {
            return response.data.data;
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        alert(error);
        throw error;
    }
};

/**
 * @DESC getChats forwarded
 */
export const getForwardedChats = async () => {
    try {
        const response = await api.get('/chats/getForwardedChatsForClient');
        if (response.data.success === true) {
            return response.data.data;
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        alert(error);
        throw error;
    }
};

/**
 * @DESC getChats solved
 */
export const getSolvedChats = async () => {
    try {
        const response = await api.get('/chats/getSolvedChatsForClient');
        if (response.data.success === true) {
            return response.data.data;
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        alert(error);
        throw error;
    }
};

/**
 * @DESC get messages by chatId
 */
export const getMessagesByChatId = async (chatId) => {
    try {
        const response = await api.get('/chats/getMessagesByChatId', { params: chatId });
        if (response.data.success) {
            return response.data.data;
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        alert(error);
        throw error;
    }
};

/**
 * @DESC send messages
 */
export const sendMessage = async (chatId, message, options) => {
    let data = { chatId: '', message: '', options: {} };
    data.chatId = chatId;
    data.message = message;
    try {
        const response = await api.post('/chats/sendMessage', data);
        if (response.data.success) {
            return response.data.data;
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        alert(error);
        throw error;
    }
};

/**
 * @DESC send files
 */
export const sendFile = async (data) => {
    try {
        const response = await api.post('/chats/sendFile', data);
        if (response.data.success) {
            return response.data.data;
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        throw error;
    }
};

/**
 * @DESC updateReadStatus
 */
export const updateReadStatus = async (chatId) => {
    let data = { chatId: chatId };
    try {
        const response = await api.put('/chats/updateReadStatus', data);
        if (response.data.success) {
            return response.data.data;
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        throw error;
    }
};

/**
 * @DESC updateFlag
 */
export const updateFlagByChatId = async (chatId, flag, adminId) => {
    let data = { chatId: '', flag: '', adminId };
    data.chatId = chatId;
    data.flag = flag;
    data.adminId = adminId;
    try {
        const response = await api.put('/chats/updateFlag', data);
        if (response.data.success) {
            alert(response.data.message);
            return response.data.data;
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        alert(error);
        throw error;
    }
};

/**
 * @DESC toggle isHandled status
 */
export const updateIsHandled = async (chatId, adminId) => {
    let data = {
        chatId: '',
        adminId: '',
    };
    data.chatId = chatId;
    data.adminId = adminId;
    try {
        const response = await api.put('/chats/updateIsHandled', data);
        if (response.data.success) {
            console.log('success update IsHandled');
        } else {
            console.log('failed update isHandled');
        }
    } catch (error) {
        throw error;
    }
};
