import React, { Component } from 'react';

class ModalForward extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { message, adminList, updateFlag } = this.props;
        return (
            <div className='modal fade' id='forwardModal' tabIndex='-1' aria-hidden='true'>
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalLongTitle'>
                                Select Admin
                            </h5>
                            <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div>
                            <div className='modal-body'>
                                <ul className='list-unstyled chat-list chat-user-list'>
                                    {adminList.map((admin) => {
                                        return (
                                            <li key={admin._id}>
                                                <a
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => updateFlag(message.id.remote._serialized, admin._id)}
                                                >
                                                    <div className='d-flex'>
                                                        <h5 className='text-truncate font-size-15 mb-1'>{admin.name}</h5>
                                                    </div>
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                                    Close
                                </button>
                                {/* <button
                                className='btn btn-primary'
                                onClick={this.handleSubmit}
                            >
                                Send
                            </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalForward;
