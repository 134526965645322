import { logout } from '../../services/user';
import { run } from '../../client';

export default function SideBarMenu(props) {
    return (
        <div className='side-menu flex-lg-column me-lg-1 ms-lg-0'>
            {/* <!-- LOGO --> */}
            <div className='navbar-brand-box'>
                <a href='/' className='logo logo-dark'>
                    <span className='logo-sm'>
                        <img src='assets/images/logo_uin.png' alt='' height='30' />
                    </span>
                </a>
            </div>
            {/* <!-- end navbar-brand-box --> */}

            {/* <!-- Start side-menu nav --> */}
            <div className='flex-lg-column my-auto'>
                <ul className='nav nav-pills side-menu-nav justify-content-center' role='tablist'>
                    <li
                        className='nav-item'
                        data-bs-toggle='tooltip'
                        data-bs-trigger='hover'
                        data-bs-html='true'
                        data-bs-placement='top'
                        title='Profile'
                    >
                        <a
                            className='nav-link'
                            id='pills-user-tab'
                            data-bs-toggle='pill'
                            href='#pills-user'
                            role='tab'
                        >
                            <i className='ri-user-2-line'></i>
                        </a>
                    </li>
                    {props.role === 'admin' ? (
                        <li
                            className='nav-item'
                            data-bs-toggle='tooltip'
                            data-bs-trigger='hover'
                            data-bs-placement='top'
                            title='Unsolved Chats'
                        >
                            <a
                                className='nav-link'
                                id='pills-unsolved-chat-tab'
                                data-bs-toggle='pill'
                                href='#pills-unsolved-chats'
                                role='tab'
                            >
                                <i className='ri-message-2-line'></i>
                            </a>
                        </li>
                    ) : (
                        false
                    )}
                    <li
                        className='nav-item'
                        data-bs-toggle='tooltip'
                        data-bs-trigger='hover'
                        data-bs-placement='top'
                        title='Solved Chats'
                    >
                        <a
                            className='nav-link'
                            id='pills-solved-chats-tab'
                            data-bs-toggle='pill'
                            href='#pills-solved-chats'
                            role='tab'
                        >
                            <i className='ri-chat-check-line'></i>
                        </a>
                    </li>
                    <li
                        className='nav-item'
                        data-bs-toggle='tooltip'
                        data-bs-trigger='hover'
                        data-bs-placement='top'
                        title='Forwarded Chats'
                    >
                        <a
                            className='nav-link active'
                            id='pills-forwarded-chat-tab'
                            data-bs-toggle='pill'
                            href='#pills-forwarded-chats'
                            role='tab'
                        >
                            <i className='ri-chat-forward-line'></i>
                        </a>
                    </li>
                    <li className='nav-item dropdown profile-user-dropdown d-inline-block d-lg-none'>
                        <span
                            className='nav-link dropdown-toggle'
                            role='button'
                            data-bs-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                        >
                            <img
                                src='assets/images/users/blank-profile.webp'
                                alt=''
                                className='profile-user rounded-circle'
                            />
                        </span>
                        <div className='dropdown-menu'>
                            {/* <span className='dropdown-item' role='button'>
                                Profile <i className='ri-profile-line float-end text-muted'></i>
                            </span> */}
                            <span className='dropdown-item' role='button' onClick={() => run(props.selfId)}>
                                Send Me Notification
                                <i className='ri-settings-3-line float-end text-muted'></i>
                            </span>
                            <div className='dropdown-divider'></div>
                            <span className='dropdown-item' role='button' onClick={() => logout(props)}>
                                Log out <i className='ri-logout-circle-r-line float-end text-muted'></i>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            {/* <!-- end side-menu nav --> */}

            <div className='flex-lg-column d-none d-lg-block'>
                <ul className='nav side-menu-nav justify-content-center'>
                    <li className='nav-item btn-group dropup profile-user-dropdown'>
                        <span
                            className='nav-link dropdown-toggle'
                            role='button'
                            data-bs-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                        >
                            <img
                                src='assets/images/users/blank-profile.webp'
                                alt=''
                                className='profile-user rounded-circle'
                            />
                        </span>
                        <div className='dropdown-menu'>
                            {/* <span className='dropdown-item' role='button'>
                                Profile
                                <i className='ri-profile-line float-end text-muted'></i>
                            </span> */}
                            <span className='dropdown-item' role='button' onClick={() => run(props.selfId)}>
                                Send Me Notification
                                <i className='ri-settings-3-line float-end text-muted'></i>
                            </span>
                            <div className='dropdown-divider'></div>
                            <span className='dropdown-item' role='button' onClick={() => logout(props)}>
                                Log out
                                <i className='ri-logout-circle-r-line float-end text-muted'></i>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            {/* <!-- Side menu user --> */}
        </div>
    );
}
