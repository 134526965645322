import React, { Component } from 'react';

class ModalSendFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            dataFile: {
                chatId: '',
                file: '',
                fileName: '',
                caption: '',
                cached: '',
            },
        };
        this.fileInput = React.createRef();
    }

    toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    handleSubmit = async (event) => {
        let { dataFile } = this.state;
        const { message, handleSendFile } = this.props;
        event.preventDefault();
        try {
            let converted = await this.toBase64(this.fileInput.current.files[0]);
            dataFile.file = converted;
            dataFile.chatId = message.id.remote._serialized;
            dataFile.fileName = this.fileInput.current.files[0].name;
            await this.setState({ dataFile });
            console.log('dataFile', dataFile);
            await handleSendFile(dataFile);
            // alert(`Selected file - ${this.fileInput.current.files[0].name}`);
            // place send to API here
        } catch (error) {
            console.log('error ', error);
        }
    };

    handleChange = (event) => {
        if (event.target.files.length) {
            this.setState({
                file: URL.createObjectURL(event.target.files[0]),
            });
        } else {
            this.setState({ file: null });
        }
    };

    handleCaption = (event) => {
        let { dataFile } = this.state;
        dataFile.caption = event.target.value;
        this.setState({ dataFile });
    };

    render() {
        return (
            <div
                className='modal fade'
                id='uploadImageModal'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalCenterTitle'
                aria-hidden='true'
            >
                <div className='modal-dialog modal-dialog-centered' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='exampleModalLongTitle'>
                                Send Image/File
                            </h5>
                            <button type='button' className='close' data-bs-dismiss='modal' aria-label='Close'>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div>
                            <div className='modal-body'>
                                <img style={{ maxWidth: '400px' }} src={this.state.file} alt='' />

                                <div className='form-group'>
                                    <label htmlFor='inputFile'>Select File</label>
                                    <input
                                        type='file'
                                        className='form-control-file'
                                        id='inputFile'
                                        ref={this.fileInput}
                                        onChange={this.handleChange}
                                    />
                                </div>
                                <div className='form-group'>
                                    <label htmlFor='caption'>Caption</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='caption'
                                        onChange={this.handleCaption}
                                    />
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                                    Close
                                </button>
                                <button className='btn btn-primary' onClick={this.handleSubmit} data-bs-dismiss='modal'>
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalSendFile;
