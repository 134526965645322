import React from 'react';

export default function NotfoundPage() {
    return (
        <div>
            <center>
                <p>Halaman tidak ditemukan</p>
            </center>
        </div>
    );
}
