import React, { Component } from 'react';
import SolvedChats from '../components/chat/SolvedChats';
import UnsolvedChats from '../components/chat/UnsolvedChats';
import ForwardedChats from '../components/chat/ForwardedChats';
import MessageList from '../components/main/MessageList';
import ModalForward from '../components/modals/ModalForward';
import Profile from '../components/main/Profile';
import SideBarMenu from '../components/main/SideBarMenu';
import {
    getMessagesByChatId,
    getUnsolvedChats,
    getSolvedChats,
    getForwardedChats,
    sendFile,
    updateFlagByChatId,
    updateIsHandled,
    updateReadStatus,
} from '../services/chat';
import socket from '../utils/socket-io';
import Loader from 'react-loader-spinner';
import { sendMessage } from '../services/chat';
import { getAdminList, getDetailsOfUser, getIdAdmin } from '../services/user';

import ModalSendFile from '../components/modals/ModalSendFile';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chats: [],
            messages: [],
            adminList: [],
            isLoading: false,
            toggleMessageOn: false,
            selfId: '',
            adminDetails: {},
            unsolvedChats: [],
            solvedChats: [],
            forwardedChats: [],
        };
    }

    // get Admin List
    fetchAdminList = async () => {
        const data = await getAdminList();
        this.setState({ adminList: data });
    };

    // update flag
    updateFlag = async (chatId, flag) => {
        const { selfId } = this.state;

        const flagUpdated = await updateFlagByChatId(chatId, flag, selfId);
        if (flagUpdated) {
            this.fetchChats();
        }
    };

    // getChats for render chatList
    fetchChats = async () => {
        const { chats, unsolvedChats, solvedChats, forwardedChats } = this.state;
        const unsolved = await getUnsolvedChats();
        const solved = await getSolvedChats();
        const forwarded = await getForwardedChats();

        this.setState({ unsolvedChats: unsolved, solvedChats: solved, forwardedChats: forwarded });
    };

    componentDidMount() {
        let { selfId, adminDetails } = this.state;
        // set selfId
        let adminData = getDetailsOfUser();
        adminDetails = adminData;
        let adminId = getIdAdmin();
        selfId = adminId;
        this.setState({ selfId, adminDetails });
        //

        this.fetchChats();
        socket.on('chatsUpdated', (data) => {
            this.fetchChats();
        });
    }

    componentWillUnmount() {
        socket.disconnect();
    }

    handleOnClickChat = async (chatId) => {
        const { messages, toggleMessageOn, selfId } = this.state;
        this.setState({ isLoading: true });
        // fetch chatMessagesByChatId
        let readChat = await updateReadStatus(chatId);
        if (readChat) {
            this.fetchChats();
        }
        let data = await getMessagesByChatId(chatId);
        let updated = await updateIsHandled(chatId, selfId);
        if (data) {
            this.setState({ messages: data, isLoading: false, toggleMessageOn: true });
        }
    };

    toggleMessageOff = () => {
        const { toggleMessageOn } = this.state;
        this.setState({ toggleMessageOn: false });
    };

    updateMessages = (newMessage) => {
        let { messages } = this.state;
        if (messages.length > 0) {
            if (
                newMessage.id.remote === messages[0].id.remote ||
                newMessage.id.remote._serialized === messages[0].id.remote
            ) {
                messages.push(newMessage);
                this.setState({ messages });
            }
        }
    };

    handleSendMessage = async (chatId, message) => {
        const { messages } = this.state;
        const newMessage = await sendMessage(chatId, message);
        messages.push(newMessage);
        this.setState({ messages });
    };

    handleSendFile = async (dataFile) => {
        const { messages } = this.state;
        const newMessage = await sendFile(dataFile);
        messages.push(newMessage);
        this.setState({ messages });
    };

    render() {
        const {
            unsolvedChats,
            solvedChats,
            forwardedChats,
            messages,
            isLoading,
            toggleMessageOn,
            adminList,
            selfId,
            adminDetails,
        } = this.state;
        return (
            <div className='layout-wrapper d-lg-flex'>
                <SideBarMenu role={adminDetails.role} selfId={selfId} {...this.props} />
                <div className='chat-leftsidebar me-lg-1 ms-lg-0'>
                    <div className='tab-content'>
                        <Profile adminDetails={adminDetails} />
                        {adminDetails.role === 'admin' ? (
                            <UnsolvedChats chats={unsolvedChats} handleOnClickChat={this.handleOnClickChat} />
                        ) : (
                            false
                        )}
                        <SolvedChats chats={solvedChats} handleOnClickChat={this.handleOnClickChat} />
                        <ForwardedChats
                            chats={forwardedChats}
                            handleOnClickChat={this.handleOnClickChat}
                            selfId={selfId}
                        />
                    </div>
                </div>
                <Loader
                    visible={isLoading}
                    type='Oval'
                    color='purple'
                    height={20}
                    width={20}
                    style={{
                        float: 'left',
                        marginRight: '8px',
                    }}
                />
                <ModalForward adminList={adminList} message={messages[0]} updateFlag={this.updateFlag} />
                <ModalSendFile message={messages[0]} handleSendFile={this.handleSendFile} />
                <MessageList
                    messages={messages}
                    toggleMessageOn={toggleMessageOn}
                    toggleMessageOff={this.toggleMessageOff}
                    handleSendMessage={this.handleSendMessage}
                    updateMessages={this.updateMessages}
                    updateFlag={this.updateFlag}
                    fetchAdminList={this.fetchAdminList}
                />
            </div>
        );
    }
}

export default Dashboard;
